import { Observable } from 'rxjs';
import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { VideoService } from 'src/app/services/video.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnChanges {
  items?: Array<any>[]= [];
  videoSource = 'https://foto.vev77.com/template/deposit/public/video/esportsbanner.mp4';

  mobileShow: boolean = false;
  constructor(
public apiVideo: VideoService
  ) {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      // true for mobile device
      //("mobile device");
      this.mobileShow = true;
    }else{
      // false for not mobile device
     // console.log("not mobile device");
      this.mobileShow = false;
  }


}

  ngOnChanges(changes: SimpleChanges): void {

  }

  retrieveTutorials(): void {
    this.apiVideo.getAll().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ key: c.payload.key, ...c.payload.val() })
        )
      )
    ).subscribe(data => {
      this.items = data;
    });
  }

}
