import { Observable } from 'rxjs';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  items: Observable<any[]>;
  tutorialsRef: AngularFireList<any>;
  constructor(public db: AngularFireDatabase) {
    this.tutorialsRef = db.list('video');
  }

  getVideo(){
   return this.items = this.db.list('video').valueChanges();
  }

  getAll(): AngularFireList<any> {
    return this.tutorialsRef;
  }


}
