<div *ngIf="mobileShow">
  <app-carousel-m></app-carousel-m>
</div>
<div *ngIf="!mobileShow">
  <app-carousel></app-carousel>
</div>


<br>
<div class="">
  <div style="margin-left: auto;margin-right: auto;text-align: center;">
    <div>

      <div style="margin-left: 15%;margin-right: 15%;color:#333333">
        <h2>La palestra gratuita quando e dove vuoi</h2>


       
<p class="giustificato">
        <strong>Pat di Fair Play</strong> è la piattaforma che mette la professionalità dei <a href="https://www.progettopat.it/patdifairplay/progetto"
          style="color:#de5b53">chinesiologi</a> del <br> <strong>Comitato Italiano Scienze Motorie - CISM -</strong> al servizio di persone sane o con patologie croniche<br> non trasmissibili, per garantire un’attività fisica sicura, ovunque.
        </p>
        <br>
        <br>
        <a href="https://www.progettopat.it/patdifairplay/registrati" style="color:#de5b53">Iscriviti gratuitamente</a>

        <br>
        <br>

        <h2>Alcuni dei nostri corsi gratuiti</h2>

      </div>



      <div *ngIf="mobileShow" style="margin-left: 6%;margin-right: 6%;">
        <div class="video-container">
          <iframe src="https://www.youtube.com/embed/YZoY_SRTQvA?rel=0" frameborder="0"
            allow=" encrypted-media; gyroscope;" allowfullscreen></iframe>
        </div>


        <div class="title">
          <div style="text-align: left; line-height:0px;">
            <h4 class="title2">Pilates per tutti</h4>
            <a href="https://www.progettopat.it/patdifairplay/dettaglioVideo/YZoY_SRTQvA" class="subtitle">Vai al corso</a>
          </div>
        </div>
        <br>
        <br>

        <div class="video-container">
          <iframe src="https://www.youtube.com/embed/Vo2uzBuapc8?rel=0" frameborder="0"
            allow=" encrypted-media; gyroscope;" allowfullscreen></iframe>

        </div>


        <div class="title">
          <div style="text-align: left!important; line-height:0px;">
            <h4 class="title2">Ricarica mente e corpo</h4>
            <a href="https://www.progettopat.it/patdifairplay/dettaglioVideo/Vo2uzBuapc8" class="subtitle">Vai al corso</a>
          </div>
        </div>

        <br>
        <br>

        <div class="video-container">
          <iframe src="https://www.youtube.com/embed/fzdkaYfUlSE?rel=0" frameborder="0"
            allow=" encrypted-media; gyroscope;" allowfullscreen></iframe>
        </div>


        <div class="title">
          <div style="text-align: left!important; line-height:0px;">
            <h4 class="title2">Introduzione a X Postural</h4>
            <a href="https://www.progettopat.it/patdifairplay/dettaglioVideo/fzdkaYfUlSE" class="subtitle">Vai al corso</a>
          </div>
        </div>
      </div>

      <div *ngIf="!mobileShow" style="margin-left: 15%;margin-right: 15%;">

    <!--   eccolo
      <div  style="margin-left: 6%;margin-right: 6%;">
        <div class="video-container" >
          <video muted autoplay loop style="width: 500px">
            <source src="gs://progettopat.appspot.com/Presentazione_Barbara_Petrilli.mp4" type="video/mp4" />
            Browser not supported
        </video>
        </div>
fine-->

        <div class="video-container">
          <iframe src="https://www.youtube.com/embed/YZoY_SRTQvA?rel=0" frameborder="0"
            allow=" encrypted-media; gyroscope;" allowfullscreen></iframe>
        </div>


        <div class="title">
          <div style="text-align: left; line-height:0px;">
            <h4 class="title2">Pilates per tutti</h4>
            <a href="https://www.progettopat.it/patdifairplay/dettaglioVideo/YZoY_SRTQvA" class="subtitle">Vai al corso</a>
          </div>
        </div>
        <br>
        <br>

        <div class="video-container">
          <iframe src="https://www.youtube.com/embed/Vo2uzBuapc8?rel=0" frameborder="0"
            allow=" encrypted-media; gyroscope;" allowfullscreen></iframe>

        </div>


        <div class="title">
          <div style="text-align: left!important; line-height:0px;">
            <h4 class="title2">Ricarica mente e corpo</h4>
            <a href="https://www.progettopat.it/patdifairplay/dettaglioVideo/Vo2uzBuapc8" class="subtitle">Vai al corso</a>
          </div>
        </div>

        <br>
        <br>

        <div class="video-container">
          <iframe src="https://www.youtube.com/embed/fzdkaYfUlSE?rel=0" frameborder="0"
            allow=" encrypted-media; gyroscope;" allowfullscreen></iframe>
        </div>



        <div class="title">
          <div style="text-align: left!important; line-height:0px;">
            <h4 class="title2">Introduzione a X Postural</h4>
            <a href="https://www.progettopat.it/patdifairplay/dettaglioVideo/fzdkaYfUlSE" class="subtitle">Vai al corso</a>
          </div>
        </div>
      </div>
      <br>
      <br>

      <div class="videosearch" style=" text-align: center; margin-top: 2%; margin-bottom: 2%;">
        <a href="https://www.progettopat.it/patdifairplay/videoCorsi">
          <button type="buttom" [routerLink]="['patdifairplay/videoCorsi']" class="btn btn-primary btnLog btn-lg" id="btnSave">TROVA
            IL
            TUO CORSO GRATUITO</button>
        </a>
      </div>
      <br>

      <div style="margin-left: 15%;margin-right: 15%;color:#333333">
        <img src="assets/imgHOME/img3.png" alt="In movimento" width="110px" height="100px">
        <br>

        <h2>Stop alla sedentarietà</h2>

        La vita sedentaria è rischiosa per la salute e nel periodo COVID-19 è difficile mantenere un livello di
        movimento costante. Con Pat di Fair Play puoi praticare attività fisica anche da casa, mantenendoti attivo quando lo desideri
        e usufruendo dei suggerimenti dei
        chinesiologi PAT.

        <br>
        <br>
        <br>
        <br>

        <img src="assets/imgHOME/img1.png" alt="Cuori" width="100px" height="110px">

        <h2>L’attività fisica sicura</h2>

        Iscrivendoti al servizio potrai praticare gli esercizi studiati dai chinesiologi Pat di Fair Play, per rispondere alle tue
        necessità di salute e per aiutarti a raggiungere i tuoi obiettivi di allenamento, in maniera affidabile e
        sicura.

        <br>
        <br>
        <br>
        <br>

        <img src="assets/imgHOME/pila.png" alt="Ricarica" width="70px" height="110px">

        <h2>Mente e corpo sempre attivi</h2>

        Pat di Fair Play ti aiuta a praticare attività fisica per migliorare la tua salute fisica e mentale, soprattutto nel periodo
        di mobilità ridotta a causa della pandemia da COVID-19.

        <br>
        <br>
        <br>
        <br>
        <h1>Un progetto educazionale</h1>
        <br>

        <a href="http://www.fairplayitalia.it/"><img src="assets/imgHOME/logo2.png " alt="Fairplay " width="200px " height="auto "></a>

        <br>
        <br>
        <h2>Con il patrocinio di</h2>
        <br>

        <img src="assets/imgHOME/logo3.jpg " alt="Coni " width="150px " height="auto ">
        <br>
        <br>
        <img src="assets/imgHOME/logo1.png " alt="Sport&Salute " width="150px " height="auto ">

        <br>
        <br>

        <h2>e con il partenariato di</h2>

        <br>

        <a href="https://scienzemotoriecism.org/"><img src="assets/imgHOME/logo4.jpg "  alt="CISM " width="150px " height="auto "></a>

        <br>
      </div>
    </div>

  </div>
</div>
